.home-illustration {
  bottom: 1rem;
  max-width: 200px;
  position: absolute;
  right: 0;
}

.hiw-illustration {
  max-height: 175px;
  max-width: 200px;
}
