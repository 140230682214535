@use 'sass:math';
@import '../variables';

.footer {
  color: var(--background-highlight-color);
  display: flex;
  flex-direction: column;
  font-size: math.div($spacing-unit, 2)rem;
  margin-top: math.div($spacing-unit, 2)rem;
  position: relative;

  @media (prefers-color-scheme: light) {
    color: var(--main-color);
  }

  p {
    margin: math.div($spacing-unit, 3)rem 0;
  }
}
