.fullScreenWrapper {
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: -6rem;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  @supports (-webkit-touch-callout: none) {
    height: 680px;
  }
}
