@use 'sass:map';
@import '../variables';

.container {
  align-items: center;
  background: var(--background-color);
  color: var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 36rem; // Looking for ~66 characters of line lenght
  min-height: 100vh;
  padding: #{ $spacing-unit }rem;
  position: relative;
  @media screen and (min-width: 640px) {
    padding: #{ pow($spacing-unit, 2) }rem;
  }
}
