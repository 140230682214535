.hiw {
  &-wrapper {

  }
  &-step {

  }
  &-illustration-wrapper {
    align-items: center;
    display: flex;
    height: 280px;
    justify-content: flex-start;
    width: 100%;
  }
  &-illustration {
    max-width: 240px;
  }
}
